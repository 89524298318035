import React from "react"
import "./_faq-list.scss"
import FaqItem from "./FaqItem"
const FaqList = ({ content }) => {
    let length = content.length
    return (
        <section className="block-fqa-list">
            <div className="container">
                {content.map((faqContent, index) => {
                    let isFinally = (length - 1 === index) ? true : false;
                    return (
                        <FaqItem key={index} content={faqContent} isFinally={isFinally} />
                    )
                })}

            </div>
        </section>
    )
}
export default FaqList
