import React from "react"
import "./_faq-list.scss"

const FaqItem = ({ content, isFinally }) => {
    const [isOpen, toggleIsOpen] = React.useState(false)
    return (
        <div className="fqa__item">
            <div onClick={() => toggleIsOpen(prev => !prev)} className="fqa__item__question d-flex-row justify-content-between">
                <div className="fqa__item__question__text">{content.mainContent}</div>
                <div className={isOpen ? "fqa__item__question__dropdown-icon current-dropdown" : "fqa__item__question__dropdown-icon"}></div>
            </div>
            {isOpen &&
                <div className="fqa__item__answer">{content.subContent}</div>
            }
            {!isFinally && <hr></hr>}
        </div>
    )
}

export default FaqItem
