import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "./_faq-footer.scss"

const FaqFooter = () =>
    <section className="block-faq-footer">
        <div className="container d-flex-row">
            <div className="faq-footer__content">
                <h2 className="faq-footer__content__title">Download Mindbody</h2>
                <div className="faq-footer__content__icons">
                    <a href="https://apps.apple.com/vn/app/mindbody-fitness-salon-spa/id689501356" target="_blank" rel="noreferrer noopener">
                        <StaticImage className="faq-footer__content__icons__apple" src="../../images/apple-store.png" alt="apple store" objectFit="fill" placeholder="blurred" />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.mindbodyonline.connect&hl=en&gl=US" target="_blank" rel="noreferrer noopener">
                        <StaticImage className="faq-footer__content__icons__google" src="../../images/google-play-badge.png" alt="google store" objectFit="fill" placeholder="blurred" />
                    </a>
                </div>
            </div>

        </div>
        <div className="faq-footer__image-container">
            <StaticImage className="faq-footer__image" src="../../images/Phone-mockup-mindbody.png" alt="mindbody image" objectFit="fill" placeholder="blurred" />
        </div>
    </section>

export default FaqFooter
