import React, { useState } from "react"
import { graphql } from "gatsby"

import {
  Layout,
  Seo,
  Hero,
  Banner,
  ErrorBoundary,
  MindBodyPage,
} from "../../index.js"
import FaqList from "../components/FaqList/FaqList"
import FaqFooter from "../components/FaqFooter/FaqFooter"

const Faq = ({ data }) => {
  let { heroTitle, heroImage, heroSubTitle } = data.faq.blocks[0].heroBlock

  let { blockContent } = data.faq.blocks[1].repeatTextBlock
  let seoTitle = data.faq.seo.title
  let metaDesc = data.faq.seo.metaDesc

  // handle toggle form
  const [toggle, setToggle] = useState(false)
  const onClick = () => {
    const healCode = document.querySelector("healcode-widget")
    if (healCode) {
      healCode.style.display = toggle ? "none" : "block"
    }
    setToggle(!toggle)
  }

  return (
    <Layout>
      <Seo
        title={data.faq.seo?.title}
        description={data.faq.seo?.metaDesc}
        featuredImage={
          data.faq.seo?.opengraphImage?.localFile.childImageSharp
            .gatsbyImageData
        }
      />
      <Banner onClick={onClick} hidden={toggle} />
      <Hero
        heroImage={heroImage}
        heroTitle={{
          normalTitle: heroTitle,
          strokeTitle: heroSubTitle,
        }}
        color="#ffcb64"
        className="hero-faq"
      />
      {toggle && (
        <ErrorBoundary>
          <MindBodyPage toggleForm={onClick} toggle={toggle} />
        </ErrorBoundary>
      )}
      <FaqList content={blockContent} />
      <FaqFooter />
    </Layout>
  )
}
export default Faq

export const query = graphql`
  query ($slug: String!, $lang: String!) {
    faq: wpPage(slug: { eq: $slug }, language: { slug: { eq: $lang } }) {
      id
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 1200)
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 1200)
            }
          }
        }
      }
      blocks {
        ... on WpAcfHeroBlock {
          heroBlock {
            heroTitle
            heroSubTitle
            heroImage {
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(quality: 90, fit: COVER) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        ... on WpAcfRepeatTextBlock {
          repeatTextBlock {
            blockContent {
              subContent
              mainContent
            }
          }
        }
      }
    }
  }
`
